import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import onClickEvents from '@helpers/home/on-click-callbacks'
import { changeQuery, getHref } from '@helpers/path'
import ROUTES from '@helpers/routes'
import { RightArrowIcon } from '@microcomponents/icons/new'
import { TEST_IDS } from '@stories/home/test/constants'
import { breakpoint } from 'theme'

import { arrayOf, bool, func, InferProps, number, oneOfType, shape, string } from 'prop-types'

type ProductGalleryProps = InferProps<typeof ProductGallery.propTypes>
const BEST_SELLERS = {
  products: [
    {
      product_name: 'product1',
      product_image: {
        url: `/static/images/best-seller-flower-min.png`,
        alt: 'product1'
      }
    },
    {
      product_name: 'product2',
      product_image: {
        url: `/static/images/best-seller-prerolls-min.png`,
        alt: 'product2'
      }
    },
    {
      product_name: 'product3',
      product_image: {
        url: `/static/images/best-seller-vape-min.png`,
        alt: 'product3'
      }
    },
    {
      product_name: 'product4',
      product_image: {
        url: `/static/images/best-seller-edible-min.png`,
        alt: 'product4'
      }
    },
    {
      product_name: 'product5',
      product_image: {
        url: `/static/images/best-seller-cbd-min.png`,
        alt: 'product5'
      }
    }
  ]
}

function ProductGallery(props: ProductGalleryProps): JSX.Element {
  const router = useRouter()

  if (props.products) {
    const bannerName = props.banner_name
    const redirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
    const redirectWithQueryParams = getHref({
      pathname: redirectLocation,
      query: changeQuery(router.query, { slug: null })
    })

    return (
      <FeaturedContainer data-e2eid={TEST_IDS.PRODUCT_GALLERY}>
        <TitleContainer>
          <Title>{props.section_title}</Title>
          <SeeMoreButton onClick={() => onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress)}>
            See more <RightArrowIcon />
          </SeeMoreButton>
        </TitleContainer>
        <ProductContainer>
          {props.products
            .filter((p) => p.banner_item !== 'see_more')
            .map((product, i) => {
              const bannerItem = product.banner_item
              return (
                <Product
                  key={i}
                  onClick={() => onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress, bannerItem)}>
                  <ProductImage
                    src={product.product_image.url}
                    alt={product.product_image.alt || product.product_name}
                  />
                </Product>
              )
            })}
        </ProductContainer>
      </FeaturedContainer>
    )
  } else {
    return null
  }
}

export function StaticProductGallery(props: ProductGalleryProps): JSX.Element {
  const router = useRouter()

  const bannerName = props.banner_name
  const redirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
  const redirectWithQueryParams = getHref({
    pathname: redirectLocation,
    query: changeQuery(router.query, { slug: null })
  })

  return (
    <FeaturedContainer data-e2eid={TEST_IDS.PRODUCT_GALLERY}>
      <TitleContainer>
        <Title>{props.section_title}</Title>
        <SeeMoreButton onClick={() => onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress)}>
          See more <RightArrowIcon />
        </SeeMoreButton>
      </TitleContainer>
      <StaticProductContainer>
        {BEST_SELLERS.products.map((product, i) => {
          return (
            <Product key={i} onClick={() => onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress)}>
              <StaticProductImage
                src={product.product_image.url}
                alt={product.product_image.alt || product.product_name}
              />
            </Product>
          )
        })}
      </StaticProductContainer>
    </FeaturedContainer>
  )
}

ProductGallery.propTypes = {
  section_title: oneOfType([bool, string]),
  banner_name: string,
  call_to_action: shape({
    url: string
  }),
  toggleAddress: func,
  products: arrayOf(
    shape({
      product_image: shape({
        url: string,
        alt: string,
        sizes: shape({
          thumbnail: string,
          'thumbnail-width': number,
          mobileImage: string,
          'mobileImage-width': number
        })
      }),
      product_name: string,
      banner_item: string
    })
  )
}

const FeaturedContainer = styled.div`
  margin: 0 auto;
  max-width: ${breakpoint.min.lg}px;
  padding: 0 20px;
  @media (min-width: ${breakpoint.min.lg}px) {
    padding: 0;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h3`
  margin: 0;
  font-family: ${({ theme }) => theme.typography.font.npBold};
  font-size: ${({ theme }) => theme.typography.size.mobile.title2}px;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }

  @media (min-width: ${breakpoint.min.md}px) {
    font-size: ${({ theme }) => theme.typography.size.desktop.title2}px;
  }
`

const ProductContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
`
const StaticProductContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
`

const Product = styled.button`
  background: none;
  margin: 20px;
  padding: 0;
  cursor: pointer;

  @media (min-width: ${breakpoint.min.lg}px) {
    margin: 25px 30px 70px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

const ProductImage = styled.img`
  display: block;
  max-height: 173px;
`

const StaticProductImage = styled.img`
  display: block;
  width: 140px;
`

const SeeMoreButton = styled.button`
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100px;
  cursor: pointer;
`

export default ProductGallery
