import styled from '@emotion/styled'
import Image from 'next/image'
import { useRouter } from 'next/router'

import onClickEvents from '@helpers/home/on-click-callbacks'
import { changeQuery, getHref } from '@helpers/path'
import ROUTES from '@helpers/routes'
import { TEST_IDS } from '@stories/home/test/constants'
import { breakpoint } from 'theme'

import { bool, func, oneOfType, shape, string } from 'prop-types'

const LogoGallery = (props) => {
  const router = useRouter()

  function handleClick() {
    const bannerName = props.banner_name
    const redirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
    const redirectWithQueryParams = getHref({
      pathname: redirectLocation,
      query: changeQuery(router.query, { slug: null })
    })

    onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress)
  }

  if (props.desktop_image && props.mobile_image) {
    return (
      <GalleryContainer data-e2eid={TEST_IDS.LOGO_GALLERY}>
        <ImageContainer className="desktop" onClick={handleClick}>
          <Image
            src="/static/images/brand-logos@2x.png"
            alt={props.desktop_image.alt || 'Featured brands logos'}
            layout="responsive"
            width={2544}
            height={144}
          />
        </ImageContainer>
        <ImageContainer className="mobile" onClick={handleClick}>
          <Image
            src="/static/images/brand-logos-mobile@2x.png"
            alt={props.mobile_image.alt || 'Featured brands logos'}
            layout="responsive"
            width={642}
            height={252}
          />
        </ImageContainer>
      </GalleryContainer>
    )
  } else {
    return null
  }
}

LogoGallery.propTypes = {
  banner_name: string,
  call_to_action: shape({
    title: string,
    url: string
  }),
  desktop_image: oneOfType([
    bool,
    shape({
      url: string
    })
  ]),
  mobile_image: oneOfType([
    bool,
    shape({
      url: string
    })
  ]),
  toggleAddress: func
}

const GalleryContainer = styled.div`
  background-color: #d9c9ef;
  border: solid ${({ theme }) => theme.colors.border};
  border-width: 1px 0;
  display: flex;
  flex-flow: row wrap;
  min-height: 150px;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;

  @media (min-width: ${breakpoint.min.md}px) {
    padding: 48px 84px;

    .mobile {
      display: none;
    }
  }

  @media (max-width: ${breakpoint.max.md}px) {
    padding: 32px 35px;

    .desktop {
      display: none;
    }
  }
`

const ImageContainer = styled.button`
  background: none;
  padding: 0;
  width: 100%;
  cursor: pointer;

  &.desktop {
    max-width: 1150px;
  }

  &.mobile {
    max-width: 400px;
  }
`

export default LogoGallery
